import { ChevronDown } from 'lucide-react';
import React from 'react';

import { Button } from '@/components/button';
import { ConditionalWrapper } from '@/components/conditional-wrapper';
import { Icon } from '@/components/icon';
import { PopoverTrigger } from '@/components/popover';

interface ComboboxTriggerProps extends React.ComponentPropsWithoutRef<typeof PopoverTrigger> {
  showShadow?: boolean;
  variant?: 'light' | 'dark';
  error?: boolean;
  isLocked?: boolean;
  isLockedFunc?: () => void;
  size?: 'small' | 'medium';
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const ComboboxTrigger = React.forwardRef<React.ElementRef<typeof PopoverTrigger>, ComboboxTriggerProps>(
  (
    { className, children, showShadow, variant, error, isLocked, isLockedFunc, size, isActive, isDisabled, ...props },
    ref,
  ) => {
    return (
      <ConditionalWrapper
        condition={!isLocked}
        // eslint-disable-next-line react/no-unstable-nested-components
        wrapper={(buttonChildren) => <PopoverTrigger asChild>{buttonChildren}</PopoverTrigger>}
      >
        <Button
          ref={ref}
          role="combobox"
          onClick={!isLocked ? props.onClick : () => isLockedFunc?.()}
          iconRight={
            !isLocked ? (
              <ChevronDown className="w-4 shrink-0 opacity-80" />
            ) : (
              <Icon name="lock" size="base" color="states.locked" />
            )
          }
          variant={variant === 'dark' ? 'darkInput' : 'lightInput'}
          className={className}
          isDisabled={isDisabled}
          truncateText
          {...props}
        >
          <div className="truncate text-left">{children}</div>
        </Button>
      </ConditionalWrapper>
    );
  },
);

ComboboxTrigger.displayName = PopoverTrigger.displayName;

export { ComboboxTrigger };

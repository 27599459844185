import { isFunction } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';

import { TextInput } from '@/components/inputs';
import { LoadingIndicator } from '@/components/ui/loading/indicator';
import { useDebounce } from '@/hooks/use-debounce';
import { useSearch } from '@/hooks/use-search';
import { OptionItem, SearchEndpointTypes } from '@/types';
import { tv } from '@/utils/styles';

interface Props {
  size?: 'small' | 'default' | 'large';
  endpoint: SearchEndpointTypes;
  name?: string;
  placeholder?: string;
  onChange?: (val: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onSearchResponseChange?: (options: OptionItem[]) => void;
  onIsSearching?: (val: boolean) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  variant?: 'plain';
  showShadow?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

/**
 * Search Input for querying different endpoints
 */
const SearchInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      size = 'small',
      name = 'search',
      endpoint,
      placeholder = 'Enter a search term',
      onChange,
      onFocus,
      onBlur,
      onSearchResponseChange,
      onIsSearching,
      onPaste,
      variant,
      showShadow = true,
      onKeyDown,
    },
    ref,
  ) => {
    const [searchInputValue, setSearchInputValue] = useState<string>(''); // This represents the exact input value
    const debouncedSearchValue = useDebounce(searchInputValue, 150);

    const { results, isLoading } = useSearch({
      queryString: debouncedSearchValue,
      endpoint,
    });

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isFunction(onChange)) {
        const { value } = e.target;
        // Set internal value
        setSearchInputValue(value);
        // Publish value
        onChange(value);
      }
    };

    useEffect(() => {
      if (isFunction(onSearchResponseChange) && results) {
        onSearchResponseChange(results);
      }
    }, [JSON.stringify(results)]);

    useEffect(() => {
      if (isFunction(onIsSearching)) {
        onIsSearching(isLoading);
      }
    }, [isLoading]);

    const { base, extra, input } = styles();

    return (
      <div className={base()}>
        <div className={extra()}>{isLoading && <LoadingIndicator />}</div>
        <TextInput
          ref={ref}
          size={size}
          label="Search"
          hideLabel
          placeholder={placeholder}
          value={searchInputValue}
          name={name}
          onChange={onInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={input()}
          onPaste={onPaste}
          variant={variant}
          showShadow={showShadow}
          onKeyDown={onKeyDown}
        />
      </div>
    );
  },
);

SearchInput.displayName = 'SearchInput';

const styles = tv({
  slots: {
    base: 'relative',
    extra: 'absolute bottom-0 right-2 top-1/2 z-30 grid size-7 -translate-y-1/2 transform items-center',
    input: 'w-full',
  },
  variants: {
    size: {
      small: { input: 'max-h-[34px]' },
      default: {},
    },
  },
});

export { SearchInput };

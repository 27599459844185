import { OptionItem, SearchQueryInterface } from '@/types';
import { joinByCommaAnd } from '@/utils/helpers';

/**
 * Removes an option from an array of options
 */
export const removeOptionFromList = (list: OptionItem[], option: OptionItem): OptionItem[] =>
  list.filter((opt) => opt.value !== option.value);

/**
 * Returns if option is in list
 */
export const isOptionInList = (list: OptionItem[], option: OptionItem): boolean =>
  !!list.find((x) => x.value === option.value);

/**
 * Gets Option Index in list
 */
export const getOptionIndex = (list: OptionItem[], option: OptionItem): number =>
  list.findIndex((x) => x.value === option.value);

/**
 * Extracts port code from string when its it brackets
 * @example ie. "Port of Melbourne (AUMEL)", returns "AUMEL"
 */
export const extractPortCode = (string: string): string => {
  const reg = /\((.*)\)/;
  const portCode = string.match(reg)?.[1];
  return portCode || string;
};

/**
 * Formats the advanced search props into a user friendly string
 */
export const getAdvancedSearchParamLabels = (searchParams: SearchQueryInterface): string[] => {
  const {
    aircraft = [],
    aircraftType = [],
    airCarriers = [],
    oceanCarriers = [],
    via = [],
    avoid = [],
    cargo,
  } = searchParams;
  const cargoLabels = cargo
    ? Object.entries(cargo).map(
        ([key, value]): OptionItem => ({
          label: key,
          value,
        }),
      )
    : [];

  // Default labels
  const defaultLabels = [...aircraft, ...aircraftType, ...airCarriers, ...oceanCarriers, ...cargoLabels].map(
    (v) => v.label,
  );
  // Avoid via labels
  const viaLabels = via.map((v) => `via: ${extractPortCode(v.label)}`);
  const avoidLabels = avoid.map((v) => `avoid: ${extractPortCode(v.label)}`);

  // Combine labels
  return [...defaultLabels, ...viaLabels, ...avoidLabels];
};

export const getAdvancedSearchSummaryLabel = (searchParams?: SearchQueryInterface, maxLabels: number = 4) => {
  if (!searchParams) return '';

  const paramLabels = getAdvancedSearchParamLabels(searchParams);
  if (!paramLabels.length) return '';

  const maxLabelsSlice = paramLabels.slice(0, maxLabels);
  const remainingLabels = paramLabels.length - maxLabels;
  return `${joinByCommaAnd(maxLabelsSlice)}${remainingLabels > 0 ? ` ${remainingLabels} more` : ''}`;
};

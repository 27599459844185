import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { SelectionItem } from '@/components/modules/advanced-search/selection/default';
import { sharedAdvancedSearchStyles } from '@/components/modules/advanced-search/styles';
import { SearchInput } from '@/components/search-input';
import { Text } from '@/components/text';
import { SEARCH_ENDPOINT_TYPES } from '@/lib/constants';
import { searchParamsSelectorState } from '@/state/search';
import { lockedDialogIsOpenState } from '@/state/user';
import { OptionItem } from '@/types';
import { trackUser } from '@/utils/tracking';

import { defaultOceanCarrierOptions } from '../config';
import { getOptionIndex, isOptionInList, removeOptionFromList } from '../utils';

interface Props {
  isLocked?: boolean;
}

const PanelOceanCarriers = ({ isLocked }: Props) => {
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [options, setOptions] = useState<OptionItem[]>(defaultOceanCarrierOptions.items);
  const [searchParams, setSearchParams] = useRecoilState(searchParamsSelectorState); // External Search State
  const setLockedDialogIsOpen = useSetRecoilState(lockedDialogIsOpenState);

  const handleOnSearchValueChange = (searchString: string) => {
    if (!searchString) {
      // No results, set back to default
      setOptions(defaultOceanCarrierOptions.items);
      setHasSearched(false);
    }
  };

  const handleSearchResponse = (newOptions: OptionItem[]) => {
    if (!isEmpty(newOptions)) {
      setOptions(newOptions);
      setHasSearched(true);
    }
  };

  const handleOnClick = (option: OptionItem) => {
    if (!isLocked) {
      addOrRemoveFromList(searchParams.oceanCarriers || [], option);
    } else {
      // Show login dialog if locked
      setLockedDialogIsOpen(true);
      // Event tracking
      trackUser.event('Advanced Search: Add Param (Locked)', {
        type: SEARCH_ENDPOINT_TYPES.OCEAN_CARRIERS,
        value: option.label,
      });
    }
  };

  const addOrRemoveFromList = (existingOptions: OptionItem[], option: OptionItem) => {
    const existingOptionIndex = getOptionIndex(existingOptions, option);

    if (existingOptionIndex > -1) {
      // Remove from list
      const updatedList = removeOptionFromList(existingOptions, option);
      setSearchParams((prev) => ({ ...prev, oceanCarriers: updatedList }));
      // Event tracking
      trackUser.event('Advanced Search: Remove Param', {
        type: SEARCH_ENDPOINT_TYPES.OCEAN_CARRIERS,
        value: option.label,
      });
    } else {
      // Add to list
      setSearchParams((prev) => ({ ...prev, oceanCarriers: [...existingOptions, option] }));
      // Event tracking
      trackUser.event('Advanced Search: Add Param', {
        type: SEARCH_ENDPOINT_TYPES.OCEAN_CARRIERS,
        value: option.label,
      });
    }
  };

  const { panel, panelFieldset, panelLegend, optionList, searchInputWrapper, searchFooter } =
    sharedAdvancedSearchStyles();

  return (
    <div className={panel()}>
      <fieldset className={panelFieldset()}>
        <legend className={panelLegend()}>Shipping Lines</legend>
        <div className={searchInputWrapper()}>
          <SearchInput
            endpoint={SEARCH_ENDPOINT_TYPES.OCEAN_CARRIERS}
            placeholder="Search by name or code"
            onChange={handleOnSearchValueChange}
            onSearchResponseChange={handleSearchResponse}
          />
        </div>
        <ul className={optionList()}>
          {options.map((option) => (
            <SelectionItem
              key={option.value}
              item={option}
              isActive={isOptionInList(searchParams.oceanCarriers || [], option)}
              onClick={handleOnClick}
            />
          ))}
        </ul>
        {!hasSearched && (
          <Text variant="sm" className={searchFooter()}>
            + 170 more (Find them using search){' '}
          </Text>
        )}
      </fieldset>
    </div>
  );
};

export { PanelOceanCarriers };

/**
 * Custom hook to determine if user is trialing advanced search and when it expires
 */

import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

import { useRecoilValueAfterMount } from '@/hooks/use-recoil-value-after-mount';
import { NEXT_AUTH_STATUSES, USER_ACCESS_FEATURES } from '@/lib/constants';
import { featuresState } from '@/state/features';
import { canAccessFeature } from '@/utils/auth';

interface UseAdvancedSearch {
  hasAccess: boolean; // Does the current user have access
  isInTrial: boolean; // If the user has access, are they in a trial or not
  expiresIn?: number; // Days until the trial expires
}

const defaultAccess = { hasAccess: false, isInTrial: false, expiresIn: 0 };

export const useAdvancedSearch = (): UseAdvancedSearch => {
  const { status, data: session } = useSession();
  const [access, setAccess] = useState<UseAdvancedSearch>(defaultAccess);
  const features = useRecoilValueAfterMount(featuresState, {});

  // Feature access
  const showUserAuth = !!features.userAuthentication;
  const canAccessAdvancedSearch = canAccessFeature(USER_ACCESS_FEATURES.ADVANCED_SEARCH, session, showUserAuth);

  useEffect(() => {
    // If user is logged in
    if (status === NEXT_AUTH_STATUSES.AUTHENTICATED) {
      // User has appropriate permissions, set and retun
      if (canAccessAdvancedSearch) {
        setAccess({ ...defaultAccess, hasAccess: true });
        return;
      }

      if (session?.trial) {
        // If there is a trial and are still days left of the trial, allow access
        setAccess({
          hasAccess: session ? session.trial.isActive : false,
          isInTrial: true,
          expiresIn: session?.trial.expiresIn,
        });
      }
      return;
    }

    // User is not logged in, or access is not allowed
    setAccess({ ...defaultAccess });
  }, [status, session, canAccessAdvancedSearch]);

  // On first run, if user is authenticated and has access, ensure we return true
  if (canAccessAdvancedSearch) {
    return { ...access, hasAccess: true };
  }

  return access;
};

import { Icon } from '@/components/icon';

interface AddRemoveIconProps {
  readonly remove?: boolean;
}

export const AddRemoveIcon = ({ remove }: AddRemoveIconProps) => {
  const iconName = remove ? 'remove-circle' : 'add-circle';
  const iconColor = remove ? '#DE7184' : 'lightBlue.500';
  return <Icon name={iconName} size="base" color={iconColor} />;
};

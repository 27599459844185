import { useRecoilState, useRecoilValue } from 'recoil';

import { Icon } from '@/components/icon';
import { ButtonIndicator } from '@/components/inputs/select-box/shared-components';
import { PromoText } from '@/components/promo-text';
import { useAdvancedSearch } from '@/hooks/use-advanced-search';
import { activeSearchParamsCountState, advancedSearchFormOpenState } from '@/state/search';
import { colors } from '@/theme/constants';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

const AdvancedSearchTrigger = ({ promoTextVariant }: { promoTextVariant?: 'white' }) => {
  const [isOpen, setIsOpen] = useRecoilState(advancedSearchFormOpenState);
  const activeSearchParamsCount = useRecoilValue(activeSearchParamsCountState);
  const { total: selectionCount } = activeSearchParamsCount;
  const { hasAccess, isInTrial } = useAdvancedSearch();

  const handleClick = () => {
    setIsOpen(!isOpen);

    // Analytics Event
    if (!isOpen) {
      trackUser.event('Advanced Search: Open');
    }
  };

  const { triggerButton, icon, trialMessage } = styles({ isActive: isOpen });

  return (
    <button type="button" className={triggerButton()} onClick={() => handleClick()} id="advanced-search-trigger">
      Advanced
      {selectionCount > 0 && <ButtonIndicator>+{selectionCount}</ButtonIndicator>}
      {!hasAccess && <Icon name="lock" size="base" className={icon()} color={`${colors.states.locked} !important`} />}
      {hasAccess && isInTrial && !selectionCount && (
        <Icon name="info-circle" size="base" className={icon()} color={`${colors.states.locked} !important`} />
      )}
      <Icon
        name="down"
        size="base"
        transform={isOpen ? 'rotate(-180deg)' : undefined}
        className={icon({ className: 'mr-0' })}
      />
      {hasAccess && isInTrial && (
        <PromoText
          parent={<span className={trialMessage()} />}
          label="Try it out"
          offset={{ crossAxis: -35, mainAxis: 4 }}
          variant={promoTextVariant}
          className="max-md:hidden"
        />
      )}
    </button>
  );
};

const styles = tv({
  slots: {
    icon: 'ml-2',
    triggerButton:
      'static inline-flex h-[var(--input-height)] cursor-pointer items-center justify-between rounded-md border border-solid border-grey-300 bg-white px-3 text-baseSm shadow-softer hover:border-grey-400 disabled:cursor-default disabled:opacity-60 disabled:hover:border-grey-300 [&_svg]:fill-text-primary',
    trialMessage: 'absolute left-0 top-0 block h-4 w-full',
  },
  variants: {
    isActive: {
      true: {
        triggerButton: '!border-grey-700',
      },
    },
  },
});

export { AdvancedSearchTrigger };

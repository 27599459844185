import { isFunction, startCase } from 'lodash';

import { Icon } from '@/components/icon';
import { Text } from '@/components/text';
import { tv } from '@/utils/styles';

export interface PillProps {
  iconLeft?: React.ReactNode | string;
  iconRight?: React.ReactNode | string;
  label: string;
  variant?:
    | 'default'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'solid'
    | 'info-solid'
    | 'success-solid'
    | 'warning-solid'
    | 'error-solid';
  size?: 'compact' | 'default' | 'small';
  className?: string;
  onClick?: () => void;
  caseSensitive?: boolean;
}

const Pill = ({
  iconLeft,
  iconRight,
  label,
  variant = 'default',
  size = 'default',
  className,
  onClick,
  caseSensitive = false,
  ...props
}: PillProps) => {
  const isClickable = isFunction(onClick);

  const { base, icon, text } = styles({ variant, size, isClickable });

  const Element = isClickable ? 'button' : 'div';

  return (
    <Element className={base({ className })} onClick={onClick} {...props}>
      {iconLeft && (
        <span className={icon()}>
          {typeof iconLeft === 'string' ? <Icon name={iconLeft} size="base" color="currentColor" /> : iconLeft}
        </span>
      )}
      <Text as="span" variant="secondary" className={text()}>
        {caseSensitive ? label : startCase(label)}
      </Text>
      {iconRight && (
        <span className={icon()}>
          {typeof iconRight === 'string' ? <Icon name={iconRight} size="base" color="currentColor" /> : iconRight}
        </span>
      )}
    </Element>
  );
};

const styles = tv({
  slots: {
    base: 'flex h-8 items-center gap-1 whitespace-nowrap rounded-xl border border-solid px-3 py-1 leading-none transition-colors [&_svg]:size-4',
    icon: 'size-4',
    text: 'leading-none',
  },
  variants: {
    variant: {
      default: 'border-grey-300 text-grey-800 [&_svg]:text-lightBlue-600',
      info: 'border-lightBlue-600 text-lightBlue-600',
      success: 'bg-success text-green-600',
      warning: 'bg-warning text-states-warning-color',
      error: 'border-error text-error',
      solid: 'border-grey-100 bg-grey-100 text-grey-800 [&_svg]:text-lightBlue-600',
      'info-solid': 'border-lightBlue-100 bg-lightBlue-100 text-lightBlue-600',
      'success-solid':
        'border-states-success-backgroundColor bg-states-success-backgroundColor text-states-success-color',
      'warning-solid':
        'border-states-warning-backgroundColor bg-states-warning-backgroundColor text-states-warning-color',
      'error-solid': 'border-states-error-backgroundColor bg-states-error-backgroundColor text-error',
    },
    isClickable: {
      true: null,
      false: null,
    },
    size: {
      default: null,
      compact: 'h-7 pl-1.5 pr-2',
      small: {
        base: 'h-6 p-1.5 pr-2',
        text: '!text-sm',
      },
    },
  },
  compoundVariants: [
    {
      variant: ['default', 'info', 'success', 'warning', 'error'],
      isClickable: true,
      class: {
        base: 'hover:bg-grey-100',
      },
    },
    {
      variant: 'solid',
      isClickable: true,
      class: {
        base: 'hover:border-grey-400',
      },
    },
    {
      variant: 'info-solid',
      isClickable: true,
      class: {
        base: 'hover:border-lightBlue-600',
      },
    },
    {
      variant: 'success-solid',
      isClickable: true,
      class: {
        base: 'hover:border-states-success-color',
      },
    },
    {
      variant: 'warning-solid',
      isClickable: true,
      class: {
        base: 'hover:border-states-warning-color',
      },
    },
    {
      variant: 'error-solid',
      isClickable: true,
      class: {
        base: 'hover:border-states-error-color',
      },
    },
  ],
});

export { Pill };

import useSWR from 'swr';

import { UldModel } from '@/lib/models/uld/types';
import { transformUlds } from '@/lib/transformers/uld';
import { UldApiInterface } from '@/types/api-types';
import { AsyncDataInterface } from '@/types/context-types';
import { request } from '@/utils/api/request';
import { getUldsUrl } from '@/utils/helpers/api-urls';

const ERROR_MESSAGE = 'Error fetching ULDs';

interface UldState extends AsyncDataInterface {
  ulds?: UldModel[];
}

export const useUlds = (): UldState => {
  const { data, isLoading, error } = useSWR<{ ulds: UldApiInterface[] }>(getUldsUrl(), request);
  const errorMessage = error ? ERROR_MESSAGE : undefined;

  const ulds = data?.ulds ? transformUlds(data.ulds) : [];

  return {
    isLoading,
    error: errorMessage,
    ulds,
  };
};

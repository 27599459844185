import { PanelAirCarriers } from './air-carriers';
import { PanelAircraftType } from './aircraft-type';
import { PanelCargo } from './cargo-specs';
import { PanelDeveloper } from './developer';
import { PanelLocations } from './locations';
import { PanelOceanCarriers } from './ocean-carriers';
import { PanelSelection } from './selection';

export {
  PanelAirCarriers,
  PanelAircraftType,
  PanelCargo,
  PanelDeveloper,
  PanelLocations,
  PanelOceanCarriers,
  PanelSelection,
};

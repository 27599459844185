import { atom } from 'recoil';

import { MapRouteInterface } from '@/types';

export const mapLocationsState = atom<MapRouteInterface | null>({
  key: 'mapLocationsState',
  default: null,
});

/**
 * Segment of the map path that is being hovered over
 */
export const activeMapSegmentState = atom<string | null>({
  key: 'activeMapSegmentState',
  default: null,
});

export const mapInstanceState = atom<google.maps.Map | null>({
  key: 'mapInstanceState',
  default: null,
  dangerouslyAllowMutability: true, // Needed because the map changes state during the render process so if we want this in state we need to allow it to change
});

// Separate map instance for the port insights because otherwise we end up
// in a race condition unmounting and mounting when we go from
// routing to the port insights and DeckGL gets confused
export const portInsightsMapInstanceState = atom<google.maps.Map | null>({
  key: 'portInsightsMapInstanceState',
  default: null,
  dangerouslyAllowMutability: true, // Needed because the map changes state during the render process so if we want this in state we need to allow it to change
});

export const mapBoundsState = atom<google.maps.LatLngBounds | null>({
  key: 'mapBoundsState',
  default: null,
});

import { Icon } from '@/components/icon';
import { LocationAutocomplete } from '@/components/location-autocomplete';
import { AutocompletePlaceTypes, LocationInterface } from '@/types';
import { PlaceInterface } from '@/types/api-types';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

export interface LocationAutocompletePairProps {
  origin?: LocationInterface;
  destination?: LocationInterface;
  initialOrigin?: string;
  initialDestination?: string;
  showLabels?: boolean;
  placeholderText?: string;
  placeTypes?: AutocompletePlaceTypes;
  colorVariant?: 'light' | 'dark';
  onChange: ({ origin, destination }: { origin?: PlaceInterface; destination?: PlaceInterface }) => void;
  className?: string;
}

const LocationAutocompletePair = ({
  origin,
  destination,
  initialOrigin = '',
  initialDestination = '',
  showLabels = true,
  placeholderText = 'City, Port, Address',
  placeTypes,
  colorVariant = 'light',
  onChange,
  className,
}: LocationAutocompletePairProps) => {
  // When the Swap Directions button is clicked
  const swapDirections = () => {
    // Update location and map states
    onChange({ destination: origin, origin: destination });
    // Analytics Event
    trackUser.event('Swap Search Directions');
  };

  const handleOnSelect = (selection: PlaceInterface | undefined, name: string) => {
    if (name === 'origin') {
      onChange({ origin: selection });
    }

    if (name === 'destination') {
      onChange({ destination: selection });
    }
  };

  const { base, swapDirectionsButton, leftCol, rightCol } = styles();

  return (
    <div className={base({ className })}>
      <div className={leftCol()}>
        <LocationAutocomplete
          name="origin"
          placeholder={placeholderText}
          label="Origin"
          showLabel={showLabels}
          onSelect={handleOnSelect}
          selectedLocation={origin}
          defaultValue={initialOrigin}
          placeTypes={placeTypes}
          colorVariant={colorVariant}
        />
      </div>
      <button onClick={swapDirections} type="button" className={swapDirectionsButton()}>
        <Icon name="arrow-right-alt" size="4" color="grey.400" />
        <span className="hidden">Swap directions</span>
      </button>
      <div className={rightCol()}>
        <LocationAutocomplete
          name="destination"
          placeholder={placeholderText}
          label="Destination"
          showLabel={showLabels}
          onSelect={handleOnSelect}
          selectedLocation={destination}
          defaultValue={initialDestination}
          placeTypes={placeTypes}
          colorVariant={colorVariant}
        />
      </div>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'relative flex w-full flex-1 flex-col sm:flex-row md:min-w-80 xl:w-[488px] xl:max-w-none xl:flex-none',
    leftCol: 'w-full sm:w-1/2 sm:[&_input]:!rounded-br-none sm:[&_input]:!rounded-tr-none',
    rightCol: 'mt-4 w-full sm:-ml-[1px] sm:mt-0 sm:w-1/2 sm:[&_input]:!rounded-bl-none sm:[&_input]:!rounded-tl-none',
    swapDirectionsButton:
      'absolute bottom-2 left-1/2 z-4 -ml-3 hidden h-6 w-6 place-items-center bg-white sm:grid hover:[&_svg]:rotate-180',
  },
});

export { LocationAutocompletePair };

import { flatten, isEmpty } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { SelectionItemCargo } from '@/components/modules/advanced-search/selection/cargo';
import { SelectionItem } from '@/components/modules/advanced-search/selection/default';
import { sharedAdvancedSearchStyles } from '@/components/modules/advanced-search/styles';
import { Text } from '@/components/text';
import { activeSearchParamsCountState, searchParamsSelectorState } from '@/state/search';
import { OptionItem, SearchQueryInterface } from '@/types';
import { isArrayOfOptionItems, isCargoOption } from '@/utils/helpers/type-checks';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import { advancedSearchCategories } from '../config';
import { removeOptionFromList } from '../utils';

interface PanelSelectionProps {
  onSearch: () => void;
}

const PanelSelection = ({ onSearch }: PanelSelectionProps) => {
  const [searchParams, setSearchParams] = useRecoilState(searchParamsSelectorState);
  const activeSearchParamsCount = useRecoilValue(activeSearchParamsCountState);

  const hasSelections = activeSearchParamsCount.total > 0;
  const selectionCategories = flatten(advancedSearchCategories.map((c) => c.items));

  // Handle removal of OptionItems from SearchParams
  const handleRemoveOption = (key: keyof SearchQueryInterface, option: OptionItem) => {
    const options = searchParams[key];
    if (isArrayOfOptionItems(options)) {
      const updatedList = removeOptionFromList(options, option);
      setSearchParams((prev) => ({ ...prev, [key]: updatedList }));
      // Event tracking
      trackUser.event('Advanced Search: Remove Param', {
        type: key,
        value: option.label,
      });
    }
  };

  // Remove cargo option
  const handleRemoveCargoOption = () => {
    setSearchParams((prev) => ({ ...prev, cargo: undefined }));
  };

  const { base, messageWrapper, text, heading } = styles();
  const { panel, panelFieldset, panelLegend, searchButton, optionList } = sharedAdvancedSearchStyles();

  return (
    <div className={panel({ className: 'justify-between', empty: !hasSelections })}>
      {hasSelections ? (
        <div className={base()}>
          <Heading as="h3" className={heading()}>
            Your selections
          </Heading>
          {selectionCategories.map((category) => {
            const key = category.value as keyof SearchQueryInterface;
            const params = key ? searchParams[key] : null;
            const hasValues = !isEmpty(params);

            return hasValues ? (
              <fieldset className={panelFieldset()} key={key}>
                <legend className={panelLegend()}>{category.label}</legend>
                <ul className={optionList()}>
                  {isArrayOfOptionItems(params) &&
                    params.map((option) => (
                      <SelectionItem
                        key={option.value}
                        item={option}
                        isActive
                        onClick={() => handleRemoveOption(key, option)}
                      />
                    ))}
                  {isCargoOption(params) && (
                    <SelectionItemCargo item={params} isActive onClick={() => handleRemoveCargoOption()} />
                  )}
                </ul>
              </fieldset>
            ) : null;
          })}
        </div>
      ) : (
        <div className={messageWrapper()}>
          <Text variant="secondary" className={text()}>
            Search selections that you make will appear here.
          </Text>
          <Text variant="secondary" className={text()}>
            Once you are happy with your parameters, press update search.
          </Text>
        </div>
      )}
      <Button type="button" className={searchButton()} iconLeft="search" disabled={!hasSelections} onClick={onSearch}>
        Update search
      </Button>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'mb-4 flex flex-col gap-4',
    messageWrapper: 'flex flex-col gap-2',
    text: 'm-0 italic text-text-secondary',
    heading: 'm-0 text-sm font-semibold uppercase leading-none text-grey-800 lg:text-sm',
  },
});

export { PanelSelection };

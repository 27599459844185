import { isEmpty } from 'lodash';
import useSWRImmutable from 'swr/immutable';

import { AIRPORT, AUTOCOMPLETE_TYPESENSE, MODE_AIR, MODE_WATER, SEAPORT, SEARCH_ENDPOINT_TYPES } from '@/lib/constants';
import { FEATURE_FLAGS } from '@/lib/constants/feature-flags';
import { CarrierSearchInterface, OptionItem, SearchEndpointTypes } from '@/types';
import { PlaceInterface } from '@/types/api-types';
import { AsyncDataInterface } from '@/types/context-types';
import { request } from '@/utils/api/request';
import { transformCarrierToOptions } from '@/utils/carrier';
import { createCarrierSearchUrl, createPlacesSearchUrl } from '@/utils/helpers';
import { cleanseStringValue } from '@/utils/helpers/cleanse-string-value';
import { isArrayOfCarrierInterface, isArrayOfPlaceInterface } from '@/utils/helpers/type-checks';
import { logEvent } from '@/utils/logger';
import { transformPlacesToOptions } from '@/utils/places';

import { useFeatureFlag } from './use-feature-flag';

const getSearchEndpoint = (endpoint: SearchEndpointTypes, queryString: string, engine: string) => {
  switch (endpoint) {
    case SEARCH_ENDPOINT_TYPES.PORTS:
      return createPlacesSearchUrl(queryString, [SEAPORT, AIRPORT], engine);
    case SEARCH_ENDPOINT_TYPES.CARRIERS:
      return createCarrierSearchUrl(queryString, undefined, engine);
    case SEARCH_ENDPOINT_TYPES.AIR_CARRIERS:
      return createCarrierSearchUrl(queryString, MODE_AIR, engine);
    case SEARCH_ENDPOINT_TYPES.OCEAN_CARRIERS:
      return createCarrierSearchUrl(queryString, MODE_WATER, engine);
    default:
      return endpoint;
  }
};

type SearchResultTypes = PlaceInterface[] | CarrierSearchInterface[] | OptionItem[];

// Handle responses, some need shaping, others are ok as is
const handleSearchQueryResponse = (options: SearchResultTypes): OptionItem[] => {
  if (isEmpty(options)) return [];

  if (isArrayOfPlaceInterface(options)) {
    // Convert Places to Options
    return transformPlacesToOptions(options);
  }
  if (isArrayOfCarrierInterface(options)) {
    // Convert Carriers to Options
    return transformCarrierToOptions(options);
  }

  return options as OptionItem[];
};

interface UseSearchProps {
  queryString: string;
  endpoint: SearchEndpointTypes;
}

interface UseSearchInterface extends AsyncDataInterface {
  results: OptionItem[] | undefined;
}

export const useSearch = ({ queryString, endpoint }: UseSearchProps): UseSearchInterface => {
  const { variant: USE_TYPESENSE_AUTOCOMPLETE } = useFeatureFlag(FEATURE_FLAGS.TYPESENSE_AUTOCOMPLETE);
  const searchEngine = USE_TYPESENSE_AUTOCOMPLETE ? AUTOCOMPLETE_TYPESENSE : '';

  const cleansedQuery = cleanseStringValue(queryString);

  const requestUrl = !cleansedQuery ? null : getSearchEndpoint(endpoint, cleansedQuery, searchEngine);

  const { data, isLoading, error } = useSWRImmutable<{ results: SearchResultTypes }>(requestUrl, request, {
    errorRetryCount: 0,
    keepPreviousData: true,
    onError: (err) => {
      logEvent.error('Search Query Error', { error: err });
    },
  });

  return {
    results: data ? handleSearchQueryResponse(data?.results || []) : undefined,
    error,
    isLoading,
  };
};

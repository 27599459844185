import { Heading } from '@/components/heading';
import { sharedAdvancedSearchStyles } from '@/components/modules/advanced-search/styles';
import { AddRemoveIcon } from '@/components/ui/add-remove-icon';
import { CARGO_TYPE_CONTAINER, CARGO_TYPE_ITEM, CARGO_TYPE_ULD } from '@/lib/constants';
import { CargoOption } from '@/types';
import { tv } from '@/utils/styles';

interface CargoOptionProps {
  item: CargoOption;
  isActive?: boolean;
  onClick?: (item: CargoOption) => void;
}

const SelectionItemCargo = ({ item, isActive = false, onClick }: CargoOptionProps) => {
  const { weight = { value: undefined, unit: undefined }, uld } = item;

  const { list, listItem } = styles();
  const { option } = sharedAdvancedSearchStyles({ isActive });

  return (
    <li>
      <button className={option()} type="button" onClick={onClick ? () => onClick(item) : undefined}>
        {Boolean(item.cargoType === CARGO_TYPE_CONTAINER) && (
          <ul className={list()}>
            <Heading className="m-0" variant="h5">
              Full container load
            </Heading>
            <li className={listItem()}>Qty: {item.quantity}</li>
            <li className={listItem()}>Size: {item.containerSize} ft</li>
          </ul>
        )}
        {Boolean(item.cargoType === CARGO_TYPE_ITEM) && (
          <ul className={list()}>
            <Heading className="m-0" variant="h5">
              Loose cargo
            </Heading>
            <li className={listItem()}>Qty: {item.quantity}</li>
            <li className={listItem()}>
              Weight: {Number(weight.value)?.toLocaleString()} {weight.unit}
            </li>
            <li className={listItem()}>
              Volume: {item.volume?.value} {item.volume?.unit}
            </li>
            <li className={listItem()}>
              Max height: {item.maxHeight?.value} {item.maxHeight?.unit}
            </li>
          </ul>
        )}
        {Boolean(item.cargoType === CARGO_TYPE_ULD) && (
          <ul className={list()}>
            <Heading className="m-0" variant="h5">
              Unit load device
            </Heading>
            <li className={listItem()}>Type: {uld?.label}</li>
            <li className={listItem()}>
              Weight: {Number(weight.value)?.toLocaleString()} {weight.unit}
            </li>
          </ul>
        )}
        <AddRemoveIcon remove={isActive} />
      </button>
    </li>
  );
};

const styles = tv({
  slots: {
    list: 'list-inside list-disc',
    listItem: 'ml-2',
  },
});

export { SelectionItemCargo };

import { useRecoilState, useSetRecoilState } from 'recoil';

import { Icon } from '@/components/icon';
import { NumberSpinner, TextInput } from '@/components/inputs';
import { InputGroup } from '@/components/inputs/input-group';
import { INPUT_TYPES } from '@/components/inputs/text-input';
import { sharedAdvancedSearchStyles } from '@/components/modules/advanced-search/styles';
import { UldComboBox } from '@/components/modules/advanced-search/uld-combo-box';
import { Select } from '@/components/ui/inputs/select';
import {
  CARGO_TYPE_CONTAINER,
  CARGO_TYPE_ITEM,
  CARGO_TYPE_ULD,
  UNIT_DISTANCE_CM,
  UNIT_DISTANCE_IN,
  UNIT_MAXHEIGHT,
  UNIT_VOLUME,
  UNIT_VOLUME_CBM,
  UNIT_VOLUME_CFT,
  UNIT_WEIGHT,
  UNIT_WEIGHT_KG,
  UNIT_WEIGHT_LB,
} from '@/lib/constants';
import { UldModel } from '@/lib/models/uld/types';
import { searchParamsSelectorState } from '@/state/search';
import { lockedDialogIsOpenState } from '@/state/user';
import { CargoOption, CargoTypes } from '@/types';
import { isCargoUnitValue } from '@/utils/helpers/type-checks';
import { tv } from '@/utils/styles';

import { containerDefaults, looseItemDefaults, uldDefaults } from '../config';

interface Props {
  isLocked?: boolean;
}

const PanelCargo = ({ isLocked }: Props) => {
  const [searchParams, setSearchParams] = useRecoilState(searchParamsSelectorState);
  const setLockedDialogIsOpen = useSetRecoilState(lockedDialogIsOpenState);

  const isActive = (cargoType: CargoTypes) => {
    return searchParams.cargo?.cargoType === cargoType;
  };

  const handleCargoOptionChange = (option: CargoOption) => {
    if (!isLocked) {
      // Clicking an existing selection, will remove it
      const updatedCargo = isActive(option.cargoType) ? undefined : option;
      setSearchParams((prev) => ({ ...prev, cargo: updatedCargo }));
    } else {
      // Show login dialog if locked
      setLockedDialogIsOpen(true);
    }
  };

  // Generic Change event where a value is provided
  const handleOnChange = (fieldName: string, value: string | number) => {
    const existingCargoParams = searchParams.cargo;
    // Update value in cargo params based on input name
    if (existingCargoParams) {
      setSearchParams((prev) => ({ ...prev, cargo: { ...existingCargoParams, [fieldName]: value } }));
    }
  };

  // Called when the Weight|Volume|Height value changes
  const handleNumberInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const existingCargoParams = searchParams.cargo || ({} as CargoOption);
    const existingUnitValue = existingCargoParams[name as keyof CargoOption];

    if (isCargoUnitValue(existingUnitValue)) {
      const updatedUnitValue = { ...existingUnitValue, value };
      // Update value in cargo params based on input name
      setSearchParams((prev) => ({ ...prev, cargo: { ...existingCargoParams, [name]: updatedUnitValue } }));
    }
  };

  // Called when the Weight|Volume|Height Unit changes
  const handleNumberUnitOnChange = (fieldName: keyof CargoOption, unit: string) => {
    const existingCargoParams = searchParams.cargo || ({} as CargoOption);
    const existingUnitValue = existingCargoParams[fieldName];

    if (isCargoUnitValue(existingUnitValue)) {
      const updatedUnitValue = { ...existingUnitValue, unit };
      // Update value in cargo params based on input name
      setSearchParams((prev) => ({ ...prev, cargo: { ...existingCargoParams, [fieldName]: updatedUnitValue } }));
    }
  };

  const handleUldChange = (uld: UldModel) => {
    setSearchParams((prev) => {
      if (prev.cargo?.cargoType) {
        return { ...prev, cargo: { ...prev.cargo, uld } };
      }
      return prev;
    });
  };

  const { cargoOptionButton, buttonTitle, buttonText, formRow, fieldRow, fieldGroup, optionWrapper } = styles();
  const { panel, panelFieldset, panelLegend, divider } = sharedAdvancedSearchStyles();

  return (
    <div className={panel()}>
      <fieldset className={panelFieldset()}>
        <legend className={panelLegend()}>What are you shipping?</legend>
        <div className={optionWrapper()}>
          <button
            type="button"
            className={cargoOptionButton({ isActive: isActive(CARGO_TYPE_CONTAINER) })}
            onClick={() => handleCargoOptionChange(containerDefaults)}
          >
            <Icon size="xl" name="fcl" />
            <div>
              <span className={buttonTitle()}>FCL</span>
              <span className={buttonText()}>Full container load</span>
            </div>
          </button>
          <button
            type="button"
            className={cargoOptionButton({ isActive: isActive(CARGO_TYPE_ITEM) })}
            onClick={() => handleCargoOptionChange(looseItemDefaults)}
          >
            <Icon size="xl" name="boxset" />
            <div>
              <span className={buttonTitle()}>Loose Cargo</span>
              <span className={buttonText()}>Pallets, boxes etc</span>
            </div>
          </button>
          <button
            type="button"
            className={cargoOptionButton({ isActive: isActive(CARGO_TYPE_ULD) })}
            onClick={() => handleCargoOptionChange(uldDefaults)}
          >
            <Icon size="xl" name="uld" />
            <div>
              <span className={buttonTitle()}>Unit Load Devices(ULD)</span>
              <span className={buttonText()}>Containerised air cargo</span>
            </div>
          </button>
        </div>
        {searchParams.cargo && <div className={divider()} />}
        {/* FLC */}
        {isActive(CARGO_TYPE_CONTAINER) && (
          <ol className={fieldGroup()}>
            <li className={formRow()}>
              <NumberSpinner
                id="quantity"
                label="Quantity"
                value={searchParams.cargo?.quantity || containerDefaults.quantity}
                name="quantity"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e.target.name, e.target.value)}
              />
              <Select
                id="containerSize"
                label="Container Size"
                placeholder="Select"
                showShadow={false}
                size="small"
                options={[
                  {
                    title: '',
                    items: [
                      { value: '20', label: '20 foot' },
                      { value: '40', label: '40 foot' },
                    ],
                  },
                ]}
                selected={searchParams.cargo?.containerSize || containerDefaults.containerSize}
                onSelect={(val) => handleOnChange('containerSize', val?.value || '')}
              />
            </li>
          </ol>
        )}
        {/* Loose */}
        {isActive(CARGO_TYPE_ITEM) && (
          <ol className={fieldGroup()}>
            <li className={formRow()}>
              <NumberSpinner
                id="quantity"
                label="Quantity"
                value={searchParams.cargo?.quantity || looseItemDefaults.quantity}
                name="quantity"
                size="small"
                max={100}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e.target.name, e.target.value)}
              />
            </li>
            <li className={fieldRow()}>
              <TextInput
                label="Total Volume"
                id="volumeValue"
                type="number"
                value={searchParams.cargo?.volume?.value || looseItemDefaults.volume?.value}
                name={UNIT_VOLUME}
                size="small"
                onChange={handleNumberInputOnChange}
              />
              <Select
                id="volumeUnit"
                label=""
                showShadow={false}
                placeholder="Select"
                size="small"
                options={[
                  {
                    title: '',
                    items: [
                      { value: UNIT_VOLUME_CBM, label: UNIT_VOLUME_CBM },
                      { value: UNIT_VOLUME_CFT, label: UNIT_VOLUME_CFT },
                    ],
                  },
                ]}
                selected={searchParams.cargo?.volume?.unit || looseItemDefaults.volume?.unit}
                onSelect={(val) => handleNumberUnitOnChange(UNIT_VOLUME, val?.value)}
              />
            </li>
            <li className={fieldRow()}>
              <TextInput
                label="Total Weight"
                id="weightValue"
                type="number"
                value={searchParams.cargo?.weight?.value || looseItemDefaults.weight?.value}
                name={UNIT_WEIGHT}
                size="small"
                onChange={handleNumberInputOnChange}
              />
              <Select
                id="weightUnit"
                label=""
                showShadow={false}
                placeholder="Select"
                size="small"
                options={[
                  {
                    title: '',
                    items: [
                      { value: UNIT_WEIGHT_KG, label: UNIT_WEIGHT_KG },
                      { value: UNIT_WEIGHT_LB, label: UNIT_WEIGHT_LB },
                    ],
                  },
                ]}
                selected={searchParams.cargo?.weight?.unit || looseItemDefaults.weight?.unit}
                onSelect={(val) => handleNumberUnitOnChange(UNIT_WEIGHT, val?.value)}
              />
            </li>
            <li className={fieldRow()}>
              <TextInput
                label="Tallest item height"
                id="maxHeightValue"
                type="number"
                value={searchParams.cargo?.maxHeight?.value || looseItemDefaults.maxHeight?.value}
                name={UNIT_MAXHEIGHT}
                size="small"
                onChange={handleNumberInputOnChange}
              />
              <Select
                id="maxHeightUnit"
                label=""
                showShadow={false}
                placeholder="Select"
                size="small"
                options={[
                  {
                    title: '',
                    items: [
                      { value: UNIT_DISTANCE_CM, label: UNIT_DISTANCE_CM },
                      { value: UNIT_DISTANCE_IN, label: UNIT_DISTANCE_IN },
                    ],
                  },
                ]}
                selected={searchParams.cargo?.maxHeight?.unit || looseItemDefaults.maxHeight?.unit}
                onSelect={(val) => handleNumberUnitOnChange(UNIT_MAXHEIGHT, val?.value)}
              />
            </li>
          </ol>
        )}
        {/* ULD */}
        {isActive(CARGO_TYPE_ULD) && (
          <ol className={fieldGroup()}>
            <li className={fieldRow({ className: 'grid-cols-1' })}>
              <UldComboBox onChange={handleUldChange} selected={searchParams.cargo?.uld?.id} />
            </li>
            <li className={fieldRow({ className: 'grid-cols-1' })}>
              <InputGroup>
                <TextInput
                  name="weight"
                  label="Total weight"
                  type={INPUT_TYPES.NUMBER}
                  onChange={handleNumberInputOnChange}
                  value={searchParams.cargo?.weight?.value || uldDefaults.weight?.value}
                />
                <Select
                  id="weightUnit"
                  hideErrorMessage
                  options={[
                    {
                      title: '',
                      items: [
                        { value: UNIT_WEIGHT_KG, label: UNIT_WEIGHT_KG },
                        { value: UNIT_WEIGHT_LB, label: UNIT_WEIGHT_LB },
                      ],
                    },
                  ]}
                  className="w-32"
                  selected={searchParams.cargo?.weight?.unit || uldDefaults.weight?.unit}
                  onSelect={(val) => handleNumberUnitOnChange(UNIT_WEIGHT, val?.value)}
                />
              </InputGroup>
            </li>
          </ol>
        )}
      </fieldset>
    </div>
  );
};

const styles = tv({
  slots: {
    buttonText: 'mt-[2px] block text-sm',
    buttonTitle: 'block text-baseSm font-semibold tracking-[0.04rem]',
    cargoOptionButton:
      'cargo-option-button grid w-full grid-cols-[4rem_1fr] items-center rounded-lg border border-solid border-grey-300 px-3 py-2 text-left shadow-sm hover:!border-lightBlue-500 hover:outline hover:!outline-lightBlue-500',
    fieldGroup: 'flex flex-col gap-3',
    fieldRow: 'grid grid-cols-[1fr_6rem] items-end gap-2',
    formRow: 'grid grid-cols-2 gap-2',
    optionWrapper: 'mt-4 flex flex-col gap-2',
  },
  variants: {
    isActive: {
      true: {
        cargoOptionButton:
          'active border-lightBlue-500 outline !outline-lightBlue-500 data-[whatintent=mouse]:focus:!outline-lightBlue-500',
      },
    },
  },
});

export { PanelCargo };

import { isEmpty } from 'lodash';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { SelectionItem } from '@/components/modules/advanced-search/selection/default';
import { sharedAdvancedSearchStyles } from '@/components/modules/advanced-search/styles';
import { Text } from '@/components/text';
import { searchParamsSelectorState } from '@/state/search';
import { lockedDialogIsOpenState } from '@/state/user';
import { OptionItem, SearchQueryInterface } from '@/types';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import { aircraftPopularOptions, aircraftTypeOptions } from '../config';
import { getOptionIndex, isOptionInList, removeOptionFromList } from '../utils';

interface Props {
  isLocked?: boolean;
}

type ListKeyType = keyof SearchQueryInterface;

const PanelAircraftType = ({ isLocked }: Props) => {
  const [searchParams, setSearchParams] = useRecoilState(searchParamsSelectorState);
  const setLockedDialogIsOpen = useSetRecoilState(lockedDialogIsOpenState);

  const handleOnClick = (listKey: ListKeyType, option: OptionItem) => {
    if (!isLocked) {
      addOrRemoveFromList(listKey, option);
    } else {
      // Show login dialog if locked
      setLockedDialogIsOpen(true);
      // Event tracking
      trackUser.event('Advanced Search: Add Param (Locked)', {
        type: listKey,
        value: option.label,
      });
    }
  };

  const addOrRemoveFromList = (listKey: ListKeyType, option: OptionItem) => {
    const existingOptions = searchParams[listKey] as OptionItem[];
    const existingOptionIndex = getOptionIndex(existingOptions, option);

    if (existingOptionIndex > -1) {
      // Remove from list
      const updatedList = removeOptionFromList(existingOptions, option);
      setSearchParams((prev) => ({ ...prev, [listKey]: updatedList }));
      // Event tracking
      trackUser.event('Advanced Search: Remove Param', {
        type: listKey,
        value: option.label,
      });
    } else {
      // Add to list
      setSearchParams((prev) => ({ ...prev, [listKey]: [...existingOptions, option] }));
      // Event tracking
      trackUser.event('Advanced Search: Add Param', {
        type: listKey,
        value: option.label,
      });
    }
  };

  const { panel, panelFieldset, panelLegend, optionList } = sharedAdvancedSearchStyles();
  const { divider, text } = styles();
  return (
    <div className={panel()}>
      <div className={panelFieldset()}>
        <legend className={panelLegend()}>{aircraftTypeOptions.title}</legend>
        <ul className={optionList()}>
          {aircraftTypeOptions.items.map((option) => (
            <SelectionItem
              key={option.value}
              item={option}
              isDisabled={!isEmpty(searchParams.aircraft)}
              isActive={isOptionInList(searchParams.aircraftType || [], option)}
              onClick={(val) => handleOnClick('aircraftType', val)}
            />
          ))}
        </ul>
      </div>
      <div className={divider()}>
        <Text className={text()}>Or</Text>
      </div>
      <div className={panelFieldset()}>
        <legend className={panelLegend()}>{aircraftPopularOptions.title}</legend>
        <ul className={optionList()}>
          {aircraftPopularOptions.items.map((option) => (
            <SelectionItem
              key={option.value}
              item={option}
              isDisabled={!isEmpty(searchParams.aircraftType)}
              isActive={isOptionInList(searchParams.aircraft || [], option)}
              onClick={(val) => handleOnClick('aircraft', val)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles = tv({
  slots: {
    divider:
      "relative my-5 w-full text-grey-600 before:absolute before:left-0 before:right-0 before:top-1/2 before:-mt-px before:border-t before:border-grey-200 before:content-['']",
    text: 'relative z-2 m-0 grid h-5 w-5 place-items-center rounded-full border border-grey-200 bg-white text-[10px] uppercase leading-none',
  },
});

export { PanelAircraftType };

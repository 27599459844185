import { Star } from 'lucide-react';
import { useSession } from 'next-auth/react';
import { useSetRecoilState } from 'recoil';

import { Tooltip } from '@/components/tooltip';
import { NEXT_AUTH_STATUSES } from '@/lib/constants';
import { SavedItemType } from '@/lib/models/saved-item/types';
import { lockedDialogIsOpenState } from '@/state/user';
import { tv } from '@/utils/styles';

export interface SavedItemButtonProps {
  onClick: () => void;
  type?: SavedItemType;
  isSaved?: boolean;
  disabled?: boolean;
  variant?: 'small' | 'default';
}

const SavedItemButton = ({
  isSaved = false,
  disabled = false,
  type,
  variant = 'default',
  onClick,
}: SavedItemButtonProps) => {
  const { status } = useSession();
  const setLockedDialogIsOpen = useSetRecoilState(lockedDialogIsOpenState);

  const isLocked = status !== NEXT_AUTH_STATUSES.AUTHENTICATED;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.blur(); // ensure tooltip gets dismissed

    if (isLocked) {
      setLockedDialogIsOpen(true);
    } else {
      onClick();
    }
  };

  const buttonLabel = `${isSaved ? 'Remove f' : 'F'}avourite ${type || ''}`;
  const tooltipLabel = `${isSaved ? 'Remove from' : 'Add this to'} your Favourites`;

  const { base, icon } = styles({ variant });

  return (
    <Tooltip content={tooltipLabel} disabled={disabled} placement="right">
      <button
        title={buttonLabel}
        aria-label={buttonLabel}
        type="button"
        className={base()}
        disabled={disabled}
        onClick={handleClick}
      >
        <Star
          className={icon()}
          fill={isSaved ? '#FECA10' : '#EEEEEE'}
          stroke={isSaved ? '#EDB900' : 'currentColor'}
          size={20}
        />
      </button>
    </Tooltip>
  );
};

const styles = tv({
  slots: {
    base: 'group rounded-full text-grey-400',
    icon: '',
  },
  variants: {
    variant: {
      default: {
        base: 'size-9 hover:enabled:bg-white',
        icon: 'size-5',
      },
      small: {
        base: 'size-6 hover:enabled:bg-grey-100',
        icon: 'size-4',
      },
    },
  },
});

export { SavedItemButton };

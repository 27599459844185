import {
  AIRCRAFT_TYPE_OPTIONS,
  CARGO_TYPE_CONTAINER,
  CARGO_TYPE_ITEM,
  CARGO_TYPE_ULD,
  FILTER_BODYTYPE_NARROW,
  FILTER_BODYTYPE_WIDE,
  FILTER_HAULING_FREIGHT,
  UNIT_DISTANCE_CM,
  UNIT_VOLUME_CBM,
  UNIT_WEIGHT_KG,
} from '@/lib/constants';
import { AdvancedSearchCategoryItem, CargoOption, OptionGroup } from '@/types';

export const advancedSearchCategories: AdvancedSearchCategoryItem[] = [
  {
    label: 'Cargo',
    value: 'cargo',
    items: [
      {
        label: 'Cargo specifications',
        value: 'cargo',
        dev: true,
      },
    ],
  },
  {
    label: 'Carriers',
    value: 'carriers',
    items: [
      {
        label: 'Airlines',
        value: 'airCarriers',
      },
      {
        label: 'Shipping Lines',
        value: 'oceanCarriers',
      },
    ],
  },
  {
    label: 'Aircraft',
    value: 'aircraft',
    items: [
      {
        label: 'Aircraft Model',
        value: 'aircraft',
        hidden: true, // stay hidden
      },
      {
        label: 'Aircraft Type',
        value: 'aircraftType',
      },
      {
        label: 'Aircraft ULD',
        value: 'aircraftUld',
        hidden: true,
      },
    ],
  },
  {
    label: 'Locations',
    value: 'locations',
    items: [
      {
        label: 'Avoid Ports / Canals',
        value: 'avoid',
      },
      {
        label: 'Route via Ports / Canals',
        value: 'via',
      },
    ],
  },
  {
    label: '👨‍💻 Debug',
    value: 'dev',
    items: [
      {
        label: 'Search configuration',
        value: 'searchConfig',
        dev: true,
      },
    ],
  },
];

export const containerDefaults: CargoOption = { cargoType: CARGO_TYPE_CONTAINER, containerSize: '40', quantity: 1 };
export const looseItemDefaults: CargoOption = {
  cargoType: CARGO_TYPE_ITEM,
  quantity: 1,
  volume: { value: 10, unit: UNIT_VOLUME_CBM },
  weight: { value: 10, unit: UNIT_WEIGHT_KG },
  maxHeight: { value: 100, unit: UNIT_DISTANCE_CM },
};
export const uldDefaults: CargoOption = {
  cargoType: CARGO_TYPE_ULD,
  weight: { value: 1000, unit: UNIT_WEIGHT_KG },
  quantity: 1,
};

export const aircraftTypeOptions: OptionGroup = {
  title: 'Aircraft Type',
  items: [
    {
      label: AIRCRAFT_TYPE_OPTIONS.FREIGHTER,
      value: FILTER_HAULING_FREIGHT,
    },
    {
      label: AIRCRAFT_TYPE_OPTIONS.WIDEBODY,
      value: FILTER_BODYTYPE_WIDE,
    },
    {
      label: AIRCRAFT_TYPE_OPTIONS.NARROWBODY,
      value: FILTER_BODYTYPE_NARROW,
    },
  ],
};

export const aircraftPopularOptions: OptionGroup = {
  title: 'Popular Cargo Aircraft',
  items: [
    {
      label: 'Boeing 737 Freighter',
      value: 'B73F',
    },
    {
      label: 'Boeing 747 Freighter',
      value: 'B74F',
    },
    {
      label: 'Boeing 777 Freighter',
      value: 'B77F',
    },
    {
      label: 'Airbus A300 Freighter',
      value: 'A30F',
    },
    {
      label: 'Airbus A320 Freighter',
      value: 'A32F',
    },
    {
      label: 'Airbus A330 Freighter',
      value: 'A33F',
    },
  ],
};

export const defaultLocationOptions: OptionGroup = {
  title: 'Locations',
  items: [
    {
      label: 'Suez Canal',
      value: 'locode EGSZC',
    },
    {
      label: 'Panama Canal',
      value: 'locode PAPAC',
      divider: true,
    },
    {
      label: 'Antwerp-Bruges (BEANR)',
      value: 'locode BEANR',
    },
    {
      label: 'Hamburg (DEHAM)',
      value: 'locode DEHAM',
    },
    {
      label: 'Long Beach (USLGB)',
      value: 'locode USLGB',
    },
    {
      label: 'Kaohsiung (TWKHH)',
      value: 'locode TWKHH',
    },
    {
      label: 'Rotterdam (NLRTM)',
      value: 'locode NLRTM',
    },
    {
      label: 'Shanghai (CNSHG)',
      value: 'locode CNSHG',
    },
    {
      label: 'Singapore (SGSIN)',
      value: 'locode SGSIN',
    },
    {
      label: 'Tokyo (JPTYO)',
      value: 'locode JPTYO',
    },
  ],
};

export const defaultAirCarrierOptions: OptionGroup = {
  title: 'Airlines',
  items: [
    {
      label: 'Cargolux',
      value: 'iata CV',
    },
    {
      label: 'Cathay Pacific Airways',
      value: 'iata CX',
    },
    {
      label: 'China Airlines',
      value: 'iata CI',
    },
    {
      label: 'Emirates',
      value: 'iata EK',
    },
    {
      label: 'FedEx',
      value: 'iata FX',
    },
    {
      label: 'Korean Air',
      value: 'iata KE',
    },
    {
      label: 'Qatar Airways',
      value: 'iata QR',
    },
    {
      label: 'Singapore Airlines',
      value: 'iata SQ',
    },
    {
      label: 'Turkish Airlines',
      value: 'iata TK',
    },
    {
      label: 'UPS Airlines',
      value: 'iata 5X',
    },
  ],
};

export const defaultOceanCarrierOptions: OptionGroup = {
  title: 'Shipping Lines',
  items: [
    {
      label: 'APL',
      value: 'scac APLU',
    },
    {
      label: 'CMA CGM',
      value: 'scac CMDU',
    },
    {
      label: 'COSCO',
      value: 'scac COSU',
    },
    {
      label: 'Evergreen',
      value: 'scac EGLV',
    },
    {
      label: 'Hapag-Lloyd',
      value: 'scac HLCU',
    },
    {
      label: 'Maersk',
      value: 'scac MAEU',
    },
    {
      label: 'MSC',
      value: 'scac MSCU',
    },
    {
      label: 'OOCL',
      value: 'scac OOLU',
    },
    {
      label: 'Wan Hai',
      value: 'scac 22AA',
    },
    {
      label: 'ZIM',
      value: 'scac ZIMU',
    },
  ],
};

import { tv } from '@/utils/styles';

export const sharedAdvancedSearchStyles = tv({
  slots: {
    divider: 'my-4 h-0 w-full border-t border-t-grey-200',
    option:
      'flex min-h-8 w-full items-center justify-between rounded-md px-2 py-1 text-left outline outline-white hover:bg-grey-100 disabled:cursor-default disabled:text-grey-400 data-[whatintent=keyboard]:outline-lightBlue-500 disabled:[&_svg]:fill-grey-400 disabled:[&_svg]:opacity-50 disabled:[&_svg]:hover:bg-transparent',
    optionList: '-mx-2 my-0 -mb-1 mt-2 p-0',
    panel: 'flex min-h-full flex-col rounded-md border border-grey-300 bg-white p-4 text-baseSm',
    panelFieldset: 'm-0 border-none p-0',
    panelLegend: 'm-0 p-0 text-sm font-semibold uppercase leading-none text-grey-800',
    searchButton: 'z-2 w-full px-4 text-baseSm font-normal tracking-wide',
    searchFooter: 'm-0 mt-2 italic text-grey-400',
    searchInputWrapper: '-mx-1 mb-1 mt-4',
  },
  variants: {
    isActive: {
      true: {
        option: 'bg-grey-100 hover:bg-grey-200',
      },
    },
    empty: {
      true: {
        panel: 'border-dashed border-grey-300 bg-transparent',
      },
    },
    hasDivider: {
      true: {
        option:
          'after:content[""] relative mb-2 after:absolute after:-bottom-1 after:left-2 after:right-2 after:border-t after:border-dashed after:border-grey-200',
      },
    },
  },
});

import React, { useEffect } from 'react';

import { ComboBox } from '@/components/ui/inputs/combobox';
import { LoadingIndicator } from '@/components/ui/loading/indicator';
import { UserMessage } from '@/components/ui/user-message';
import { useUlds } from '@/hooks/use-ulds';
import { UldModel } from '@/lib/models/uld/types';
import { OptionItem } from '@/types';

export interface UldComboBoxProps {
  selected?: string;
  onChange: (v: UldModel) => void;
}

const uldsToOptions = (ulds: UldModel[]): OptionItem[] => {
  return ulds.map((uld) => ({ value: uld.id, label: uld.label }));
};

const UldComboBox = ({ onChange, selected }: UldComboBoxProps) => {
  const { ulds, error, isLoading } = useUlds();
  const options = ulds?.length ? uldsToOptions(ulds) : [];

  const handleChange = (selectedValue: OptionItem) => {
    const selectedUld = ulds?.find((uld) => uld.id === selectedValue.value);
    if (selectedUld) {
      onChange(selectedUld);
    }
  };

  // Once the ulds have been loaded, automatically set the first value as
  // the selected option
  useEffect(() => {
    if (!isLoading && ulds?.length && !selected) {
      handleChange(options[0]);
    }
  }, [ulds, error, isLoading]);

  if (error) return <UserMessage variant="error" body={error} size="sm" />;
  if (isLoading) return <LoadingIndicator />;

  return (
    <ComboBox
      id="uld"
      placeholder="ULD"
      label="Container type"
      options={[{ title: 'Unit load device', items: options }]}
      onSelect={handleChange}
      selected={selected}
    />
  );
};

export { UldComboBox };

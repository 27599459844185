import { isEmpty } from 'lodash';

import { CargoOption, CargoUnitValue, CarrierSearchInterface, OptionItem } from '@/types';
import { PlaceInterface } from '@/types/api-types';

/**
 * Array is of OptionItem[]
 */
export const isArrayOfOptionItems = (arr: OptionItem[] | any[] | any): arr is OptionItem[] => {
  if (arr.length) {
    const firstObj = arr[0];
    return 'value' in firstObj && firstObj.label !== undefined;
  }
  return false;
};

/**
 * Returns if is an Array of PlaceInterface
 */
export const isArrayOfCarrierInterface = (arr: CarrierSearchInterface[] | any[]): arr is CarrierSearchInterface[] => {
  if (arr.length) {
    const firstObj = arr[0];
    return 'type' in firstObj && firstObj.identifiers !== undefined;
  }
  return false;
};

/**
 * Returns if is an Array of PlaceInterface
 */
export const isArrayOfPlaceInterface = (arr: PlaceInterface[] | any[]): arr is PlaceInterface[] => {
  if (arr.length) {
    const firstObj = arr[0];
    return 'placeId' in firstObj && firstObj.location !== undefined;
  }
  return false;
};

/**
 * CargoOption
 */
export const isCargoOption = (obj: CargoOption | any): obj is CargoOption => {
  if (!obj || Array.isArray(obj) || isEmpty(obj)) return false;
  return 'cargoType' in obj && obj.quantity !== undefined;
};

/**
 * CargoOption
 */
export const isCargoUnitValue = (obj: CargoUnitValue | any): obj is CargoUnitValue => {
  if (!obj || Array.isArray(obj) || isEmpty(obj)) return false;
  return 'unit' in obj && obj.value !== undefined;
};

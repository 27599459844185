import { isFunction } from 'lodash';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Button } from '@/components/button';
import { LocationAutocompletePair } from '@/components/location-autocomplete-pair';
import { AdvancedSearchTrigger } from '@/components/modules/advanced-search/trigger';
import { SavedItemButton } from '@/components/ui/saved-item-button';
import { useSavedItems } from '@/hooks/use-saved-items';
import { SAVED_ITEM_TYPE, SEARCH_FORM_VARIANTS } from '@/lib/constants';
import { routeSearchDestinationState, routeSearchOriginState } from '@/state/routes';
import { isSearchingRoutesState, searchLimitReachedState } from '@/state/search';
import { SearchFormVariants } from '@/types';
import { PlaceInterface } from '@/types/api-types';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

export interface SearchBarProps {
  showLabels?: boolean;
  buttonLabel?: string;
  searchVariant?: SearchFormVariants;
  onSearch: () => void;
  onClickSaveItem?: () => void;
  className?: string;
}

const SearchBar = ({
  showLabels = true,
  buttonLabel = 'Search',
  searchVariant = SEARCH_FORM_VARIANTS.DEFAULT,
  onSearch,
  onClickSaveItem,
  className,
}: SearchBarProps) => {
  const isSavedItemsEnabled = searchVariant !== SEARCH_FORM_VARIANTS.HOMEPAGE;
  const { getSavedItemByPath, deleteSavedItem } = useSavedItems({ isEnabled: isSavedItemsEnabled });
  const router = useRouter();

  const savedItem = getSavedItemByPath({ path: router.asPath, type: SAVED_ITEM_TYPE.ROUTE });

  // Get state
  const isSearchingRoutes = useRecoilValue(isSearchingRoutesState);
  const searchLimitReached = useRecoilValue(searchLimitReachedState);
  const [origin, setOrigin] = useRecoilState(routeSearchOriginState);
  const [destination, setDestination] = useRecoilState(routeSearchDestinationState);

  const hasValues = !!(origin && destination);
  const canSearch = hasValues && !searchLimitReached;

  const handleClickSearch = () => {
    // Trigger search
    onSearch();
    // Analytics Event
    trackUser.event('Search', { origin: origin?.name || '', destination: destination?.name || '' });
  };

  // When an autocomplete option is selected/changes
  const handleChangeLocations = ({
    origin: changedOrigin,
    destination: changedDestination,
  }: {
    origin?: PlaceInterface;
    destination?: PlaceInterface;
  }) => {
    if (changedOrigin) {
      setOrigin(changedOrigin);
    }
    if (changedDestination) {
      setDestination(changedDestination);
    }
  };

  const handleClickSavedItem = () => {
    if (isFunction(onClickSaveItem)) {
      if (savedItem) {
        deleteSavedItem(savedItem);
      } else {
        onClickSaveItem();
      }
    }
  };

  const { base, autocompleteGroup, autocomplete, filtersGroup, filters, searchButton } = styles({ searchVariant });

  return (
    <div className={base({ className })}>
      <div className={autocompleteGroup()}>
        <LocationAutocompletePair
          origin={origin || undefined}
          destination={destination || undefined}
          showLabels={showLabels}
          onChange={handleChangeLocations}
          className={autocomplete()}
        />
      </div>
      <div className={filtersGroup()}>
        <div className={filters()}>
          <AdvancedSearchTrigger
            promoTextVariant={searchVariant === SEARCH_FORM_VARIANTS.HOMEPAGE ? 'white' : undefined}
          />
        </div>
        <div className="flex items-center gap-2 max-sm:w-full">
          <Button
            iconLeft="search"
            isLoading={isSearchingRoutes}
            disabled={!canSearch}
            onClick={handleClickSearch}
            className={searchButton()}
            data-cy="route-search-button"
          >
            {buttonLabel}
          </Button>
          {onClickSaveItem && isSavedItemsEnabled ? (
            <SavedItemButton
              onClick={handleClickSavedItem}
              type={SAVED_ITEM_TYPE.ROUTE}
              isSaved={!!savedItem}
              disabled={!canSearch}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'relative z-5 flex flex-col border-b border-grey-300 bg-grey-100 px-4 py-3 md:flex-row md:items-end',
    autocompleteGroup: 'autocomplete-group mb-4 md:mb-0 md:mr-2', // @TODO: Remove the .autocomplete-group class if the SearchBlock has been deleted
    filtersGroup: 'flex items-end justify-between gap-2 lg:gap-2',
    filters: 'relative hidden sm:grid sm:grid-flow-col sm:items-center sm:gap-2',
    searchButton:
      'z-2 h-[--input-height] pl-base pr-base text-baseSm font-normal tracking-[0.02rem] max-sm:w-full sm:pl-base sm:pr-base',
    autocomplete: null,
  },
  variants: {
    searchVariant: {
      default: {},
      homepage: {
        base: 'border-none bg-transparent p-0',
        autocompleteGroup: '[&_label]:text-white',
        autocomplete: 'lg:!w-[640px]',
      },
    },
  },
});

export { SearchBar };

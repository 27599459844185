/**
 * Based on https://ui.shadcn.com/docs/components/combobox
 */

import { flatMap, get, isEmpty } from 'lodash';
import { Check } from 'lucide-react';
import { Fragment, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { Icon } from '@/components/icon';
import { Error } from '@/components/inputs/error';
import { Label } from '@/components/inputs/label';
import { Popover, PopoverContent } from '@/components/popover';
import { Tooltip } from '@/components/tooltip';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from '@/components/ui/inputs/command';
import { lockedDialogIsOpenState } from '@/state/user';
import { OptionGroup, OptionItem } from '@/types';
import { FieldErrors } from '@/types/form-types';
import { cn, tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import { ComboboxTrigger } from './trigger';

interface ComboBoxProps extends FieldErrors {
  id: string;
  label?: string;
  placeholder?: string;
  options: OptionGroup[];
  selected?: string;
  disabled?: boolean;
  onSelect?: (value: OptionItem) => void;
  onBlur?: () => void;
  isActive?: boolean;
  trackingCategory?: string;
  variant?: 'light' | 'dark';
  size?: 'small' | 'medium';
  showShadow?: boolean;
  isLocked?: boolean;
  hideErrorMessage?: boolean;
  tooltipContent?: React.ReactNode | string;
  errorMessage?: string;
  isOpen?: boolean;
  className?: string;
  isLabelOptional?: boolean;
}

const ComboBox = ({
  id,
  label,
  placeholder,
  options,
  selected,
  disabled,
  onSelect,
  isActive = false,
  trackingCategory = 'Combobox',
  isLocked,
  hideErrorMessage,
  tooltipContent,
  className,
  isOpen: defaultIsOpen,
  errors,
  variant = 'light',
  size = 'medium',
  avoidCollisions = true,
  side,
  isLabelOptional,
  ...props
}: ComboBoxProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const fieldError = get(errors, id);
  const errorMessage = fieldError?.message;

  const setLockedDialogIsOpen = useSetRecoilState(lockedDialogIsOpenState);

  const flatOptions = flatMap(options, 'items');
  const validSelectedValue = flatOptions.find((option) => option.value === selected)?.value;

  const optionsWithEmptyItemsRemoved = options.filter((group) => !isEmpty(group.items));

  const handleValueChange = (value: string) => {
    // Cmdk makes all the values lowercase so we need to match that
    const selectedOption = flatOptions.find((option) => option.label.toLowerCase() === value);

    if (selectedOption && onSelect) {
      onSelect(selectedOption);
    }
  };

  const handleLockedDialogOpen = () => {
    setLockedDialogIsOpen(true);
    // Analytics Event
    trackUser.event(`Click Locked: ${trackingCategory}`, { id });
  };

  const { trigger, error } = comboBoxStyles({ isActive, fieldError: !!fieldError, size });

  return (
    <div className={className} {...props}>
      <div className="flex">
        <Label isHidden={!label} htmlFor={id} isOptional={isLabelOptional}>
          {label || 'Select an option'}
        </Label>
        {Boolean(tooltipContent) && (
          <div className="ml-auto cursor-pointer">
            <Tooltip content={tooltipContent} variant="dark" maxWidth="260px">
              <span>
                <Icon name="question-mark-circled" size={3} />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      <Popover open={isOpen} onOpenChange={setIsOpen} {...props}>
        <ComboboxTrigger
          id={id}
          variant={variant}
          isLocked={isLocked}
          isLockedFunc={handleLockedDialogOpen}
          aria-expanded={isOpen}
          isDisabled={disabled}
          className={trigger()}
        >
          {validSelectedValue ? flatOptions.find((option) => option.value === validSelectedValue)?.label : placeholder}
        </ComboboxTrigger>
        {/* We force it to appear underneath the trigger, otherwise we don't see the search field and it becomes difficult to use */}
        <PopoverContent className="border-none p-0 shadow-none" avoidCollisions={avoidCollisions} side={side}>
          {Boolean(!isLocked) && (
            <Command>
              <CommandInput placeholder="Search..." />

              <CommandEmpty>No results found</CommandEmpty>
              {
                optionsWithEmptyItemsRemoved.reduce(
                  (result, group, groupIndex) => {
                    result.groups.push(
                      // eslint-disable-next-line react/no-array-index-key
                      <Fragment key={groupIndex}>
                        <CommandGroup heading={group.title}>
                          {group.items.map((item) => {
                            return (
                              <CommandItem
                                onSelect={(currentValue) => {
                                  handleValueChange(currentValue === selected ? '' : currentValue);
                                  setIsOpen(false);
                                }}
                                key={`${item.value}-${item.label}-${id}`}
                                // Annoyingingly, cmdk bases the text search on the value, not what the
                                // user can see (The 'label') so we need to set the value as the label
                                value={item.label}
                                disabled={item.disabled}
                                className={cn({ 'bg-blue-50': selected === item.value })}
                              >
                                <span className="mr-2">{item.label}</span>
                                <Check
                                  className={cn(
                                    'ml-auto h-4 w-4 text-lightBlue-700',
                                    selected === item.value ? 'opacity-100' : 'opacity-0',
                                  )}
                                />
                              </CommandItem>
                            );
                          })}
                        </CommandGroup>
                        {Boolean(
                          groupIndex < options.length - 1 &&
                            options.filter((option) => !isEmpty(option.items)).length > 1,
                        ) && <CommandSeparator />}
                      </Fragment>,
                    );

                    return result;
                  },
                  { groups: [] as React.ReactNode[] },
                ).groups
              }
            </Command>
          )}
        </PopoverContent>
      </Popover>
      {Boolean(errorMessage && !hideErrorMessage) && <Error className={error()}>{errorMessage}</Error>}
    </div>
  );
};

export const comboBoxStyles = tv({
  slots: {
    trigger: null,
    error: 'mb-2 text-forms-states-error',
  },
  variants: {
    fieldError: {
      true: {
        trigger:
          'border-forms-states-error text-forms-states-error !shadow-[0_0_0_1px_theme(colors.forms.states.error)]',
      },
    },
    size: {
      small: {
        trigger: 'h-[var(--input-height-small)] sm:!px-3',
      },
      medium: {},
    },
    showShadow: {
      true: {
        trigger: 'shadow-softer',
      },
    },
    isActive: {
      true: {
        trigger: '!border-lightBlue-600',
      },
    },
  },
});

export { ComboBox };

import { sharedAdvancedSearchStyles } from '@/components/modules/advanced-search/styles';
import { AddRemoveIcon } from '@/components/ui/add-remove-icon';
import { OptionItem } from '@/types';

export interface SelectionItemProps {
  item: OptionItem;
  isDisabled?: boolean;
  isActive?: boolean;
  hasDivider?: boolean;
  onClick?: (item: OptionItem) => void;
}

const SelectionItem = ({ item, isDisabled, isActive = false, hasDivider = false, onClick }: SelectionItemProps) => {
  const { option } = sharedAdvancedSearchStyles({ isActive, hasDivider });
  return (
    <li>
      <button
        className={option()}
        type="button"
        onClick={onClick ? () => onClick(item) : undefined}
        disabled={isDisabled}
      >
        <span>{item.label}</span>
        <AddRemoveIcon remove={isActive} />
      </button>
    </li>
  );
};

export { SelectionItem };

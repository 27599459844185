import { UldModel } from '@/lib/models/uld/types';
import { UldApiInterface } from '@/types/api-types';
import { getPreferredIdentifier } from '@/utils/helpers';

export const transformUlds = (ulds: UldApiInterface[]): UldModel[] => {
  return ulds.map((uld) => {
    const preferredIdentifier = getPreferredIdentifier(uld.identifiers);
    return {
      id: `${uld.name}-${preferredIdentifier?.value}`,
      label: `${uld.name} (${preferredIdentifier?.value})`,
      ...uld,
    };
  });
};

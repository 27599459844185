import { useRecoilState } from 'recoil';

import { TextInput } from '@/components/inputs';
import { sharedAdvancedSearchStyles } from '@/components/modules/advanced-search/styles';
import { developerSearchParamsState } from '@/state/search';
import { tv } from '@/utils/styles';

const PanelDeveloper = () => {
  const [searchParams, setSearchParams] = useRecoilState(developerSearchParamsState);

  const handleParamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchParams((prev) => ({ ...prev, [name]: Number(value) }));
  };

  const { flex, left, right } = styles();
  const { panel, panelFieldset, panelLegend, divider } = sharedAdvancedSearchStyles();

  return (
    <div className={panel()}>
      <fieldset className={panelFieldset()}>
        <legend className={panelLegend()}>Port Selection</legend>
        <div className={divider()} />
        <ol className={flex()}>
          <li className={right()}>
            <TextInput
              label="Airport #"
              type="number"
              value={searchParams.airports || ''}
              name="airports"
              onChange={handleParamChange}
            />
          </li>
          <li className={left()}>
            <TextInput
              label="Seaport #"
              type="number"
              value={searchParams.seaports || ''}
              name="seaports"
              onChange={handleParamChange}
            />
          </li>
        </ol>
      </fieldset>
      <fieldset className={panelFieldset()}>
        <legend className={panelLegend()}>Rail Selection</legend>
        <div className={divider()} />
        <ol className={flex()}>
          <li className={right()}>
            <TextInput
              label="Max Dist. (km)"
              type="number"
              value={searchParams.maxRailDistance || ''}
              name="maxRailDistance"
              onChange={handleParamChange}
            />
          </li>
          <li className={left()}>
            <TextInput
              label="Max Ratio"
              type="number"
              value={searchParams.maxRailDistanceRatio || ''}
              name="maxRailDistanceRatio"
              onChange={handleParamChange}
            />
          </li>
        </ol>
      </fieldset>
    </div>
  );
};

const styles = tv({
  slots: {
    flex: 'mb-4 flex',
    left: 'ml-1',
    right: 'mr-1',
  },
});

export { PanelDeveloper };

/**
 * This component is used to group inputs together
 * e.g. a text field and a select box
 *
 * For this to work properly, the names of each input need
 * to be added to the names prop as an array. Also, the inputs
 * need to have their error messages removed so this
 * component can handle them.
 *
 * @NOTE: Only currently tested with input and select,
 * add more as needed.
 */

import { get } from 'lodash';

import { Error } from '@/components/inputs/error';
import { FieldErrors } from '@/types/form-types';
import { tv } from '@/utils/styles';

interface Props extends FieldErrors {
  names?: string[];
  children: React.ReactNode;
}

const InputGroup = ({ children, names = [], errors, ...props }: Props) => {
  const hasErrors = names?.some((name) => get(errors, name));

  const { base, fieldset, errorWrapper, error } = styledInputGroup({ hasErrors: !!hasErrors });

  return (
    <div className={base()} {...props}>
      <fieldset className={fieldset()}>{children}</fieldset>
      {hasErrors && (
        <Error className={errorWrapper()}>
          {names.map((name) => {
            const errorMessage = get(errors, `${name}.message`);
            if (errorMessage) {
              return (
                <span key={name} className={error()}>
                  {errorMessage}
                </span>
              );
            }
            return null;
          })}
        </Error>
      )}
    </div>
  );
};

const styledInputGroup = tv({
  slots: {
    base: null,
    fieldset:
      'flex items-end [&_div:first-of-type_button]:rounded-r-none [&_div:first-of-type_input]:rounded-r-none [&_div:last-of-type_button]:-ml-px [&_div:last-of-type_button]:rounded-l-none [&_div:last-of-type_input]:-ml-px [&_div:last-of-type_input]:rounded-l-none [&_input:focus]:relative [&_input:focus]:z-2',
    errorWrapper: 'mb-2',
    error: 'block w-full',
  },
  variants: {
    hasErrors: {
      true: {
        error: 'text-forms-states-error',
      },
    },
  },
});

export { InputGroup };
